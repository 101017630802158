import { useService } from "@with-nx/hooks-n-helpers";
import { useEffect, useState } from "react";

export type UseDropTag = {
  id: string;
  name: string;
  coverDrop?: {
    id: string;
    name: string;
    attachments: {
      name: string;
      blob: {
        signedUrl: string;
      };
    }[];
  };
};

export const useDropTags = ({
  page = 1,
  per = 12,
  region = 1,
  category = "Setting",
}: {
  page?: number;
  per?: number;
  region?: number;
  category?: string;
}) => {
  const [loading, _loading] = useState<boolean>(false);
  const [data, _data] = useState<UseDropTag[]>([]);
  const [pages, _pages] = useState<number>(0);

  useEffect(() => {
    const makeRequestToMicroservice = useService("microservice", {
      cache: 1_800_000,
    });

    let current = true;

    (async () => {
      try {
        const request = await makeRequestToMicroservice(
          "GET",
          `/ecommerce/products/search/${region}/digital-drops/tags`,
          {
            "pagination[page]": page.toString(),
            "pagination[pageSize]": per.toString(),
            "filter[category][0]": category,
            "includes[coverDrop]": "true",
            "includes[coverDrop_attachments]": "true",
          }
        );

        if (request?.["result"] && current) {
          _data(request?.["result"]);
          _pages(Math.ceil(request?.["total"] / request?.["pageSize"]));
        }
      } catch (error) {
        _data([]);
        _pages(0);
      } finally {
        _loading(false);
      }
    })();

    return () => {
      current = false;
    };
  }, [page, region, category]);

  return {
    loading,
    data,
    pages,
  };
};

export default useDropTags;
